.home {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (max-width: 600px) {
  .home {
    width: 100%;
  }
}
