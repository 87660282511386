.homeContent{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 70%;
    padding: 15px;
    min-height: 70vh;
    margin: 0 auto;
    
}
.location-container{
  display: flex;
}
.location-icon{
  width: 25px;
  
}
.devName span{
  color: #2978B5;
}
.devName{
  font-size: 36px;
}
.home-icons{
  display: flex;
  margin: 15px 0;
  align-items: center;
}
.home-icon{
  margin: 0 5px;
  width: 40px;
  height: 40px;
}

  /* Add the underline */
  .aboutMeLink::before {
    content: "";
    position: absolute;
    width: 0%;
    height: 2px;
    bottom: -2px;
    left: 0;
    background-color: salmon;
    transition: width 0.3s;
  }
  
  /* Add the hover effect */
  .aboutMeLink:hover::before {
    width: 100%;
  }
 
  .arrow {
    width: 40px;
  height: 40px;
  background-image: url('../../assets/pagedown.gif');
  background-size: cover;
  background-repeat: no-repeat;
    top: 40%;
    position: relative;
  }
  
  @keyframes move-animation {
    from {
      top: 25%;
    }
    to {
      top: 40%;
    }
  }
h1.devName{

}
@media only screen and (max-width: 600px) {
    .homeContent{
        text-align: center;
        height: 80vh;
        align-items: center;
        width: 90%;
        margin: 0 auto;
        
    }
    .arrow {
      top: 25%;
      position: relative;
    }
}
