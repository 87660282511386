
  
 .custom-button{
    display: block;
    width: 150px;
    height: 30px;
    font-size: 12px;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    text-decoration: none;
    color: #2978B5;
    border: 1px solid #2978B5;
    letter-spacing: 2px;
    text-align: center;
    position: relative;
    transition: all .25s;
    margin: 0 5px;
  }
  
 .custom-button span{
    display: inline-block;
    padding: 5px;
    position: relative;
    z-index: 2;
 }
  .version2{
    background-color: #2978B5;
    color: white;
    margin-left: 0;
  }
    
  .custom-button:after{
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background: #2978B5;
    transition: all .25s;
  }
  .custom-button:hover{
    color: #fff;
  }
  
  .custom-button:hover:after{
    width: 100%;
  }