.skillWrapper h4{
    padding: 0 5px;
}
.skillWrapper{
    text-align: center;
}
 .skillWrapper p{
    font-size: 16px;
}
.skillItem>li{
    display: inline-block;
    margin: 0 5px;
    background: #7EB8D6FF;
    color: white;
    font-weight: bold;
    padding:0 5px;
    border-radius: 5px;
    font-size: 14px;
}
.skillItem>li:first-child{
    margin-left: 5px;
}
ul.skillItem{
    margin: 0;
    padding: 0;
}
@media only screen and (max-width: 600px) {
   
    .skillItem>li{
        margin: 5px;

    }
}