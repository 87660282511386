.contact-form {
    display: flex;
    flex-direction: column;
    max-width: 400px;
    margin-top: 5%;
  }
  
  input,
  textarea {
    padding: 10px;
    margin-bottom: 15px;
    border-radius: 4px;
    border: 1px solid #ccc;
  }
  textarea{
    height: 100px;
  }
.hightlight{
  color: #2978B5;
}