.projects-container {
  padding: 15px;
  display: flex;
  flex-direction: column;
}
h1.projectTitle {
  width: 100%;
  float: left;
}
.introduction-projects {
  width: 60%;
  text-align: justify;
  margin-left: auto;
  margin-top: 23px;
}
.project-tabs > a {
  text-decoration: none;
  color: black;
  width: 100%;
  text-align: center;
  margin: 5px;
}
.project-tabs {
  display: flex;
  flex-direction: column;
}

.additional-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.projectCard {
  background-color: #f5efe7;
  padding: 0 10px;
  margin: 5px 0;
}
.projectCard h3 {
  font-size: 14px;
}
.hideProj {
  display: none;
}

@media only screen and (max-width: 600px) {
  .introduction-projects {
    margin: 0;
    width: 100%;
  }
  .introduction-projects {
    padding: 20px 0;
  }
  .links {
    padding: 20px 0;
  }
  h1.projectTitle {
    width: 100%;
    float: none;
  }
}
