span img {
  width: 24px;
  margin: 0 5px;
}
.Icons {
  display: flex;
  background-color: #fcfcfc;
}
.email {
  animation: ease-in 0.1s;
  display: none;
  background-color: #01257d;
  color: white;
  border-radius: 20px;
}
.wrapper a {
  text-decoration: none;
}
