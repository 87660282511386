.educationContent{
    display:flex;
    flex-direction: row;
    justify-content: space-between;
}
.imagepin{
    height: 20px;
}
.experience-container{
    border-left: 1px solid #d1d1d1;
    padding: 0 15px;
}
.Job{
    position: relative;
}
.Job li{
    font-size: 14px;
    text-decoration: none;

}
p{
    font-size: 14px;
}
.circle{
    position: absolute;
    top: 0;
    left: -23px;
    width: 15px;
    background-color: whitesmoke;
    border-radius: 20px;
}
.datesworked{
    float: right;
}
.section-title {
    border-bottom: 2px solid #7EB8D6FF;
}
@media only screen and (max-width: 600px) {
    .educationContent{
        display:flex;
        flex-direction: column;
      
    }
    
}
.arrowright{
    width: 30px;
}
.linkstonext{
    margin: 20px 0;
    display: flex;
    align-items: center;
    font-size: 12px;
    
}
.linkstonext img{
    margin-left: 10px;
    animation: moveRightAndBack 2s infinite;
}
@keyframes moveRightAndBack {
    0% {
      transform: translateX(0);
    }
    50% {
      transform: translateX(10px);
    }
    100% {
      transform: translateX(0);
    }
  }