.desc-container {
  background-color: #f5f5f5;
  padding: 15px;
}

.desc-container img {
  width: 500px;
  height: 350px;
  margin-bottom: 15px;
}
.img-and-description {
  display: flex;
}
.title {
  font-size: medium;
}
.project-desc {
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.project-links img {
  height: 24px;
  width: 24px;
  margin: 0 10px;
}
.project-links {
  display: flex;
  align-items: center;
  margin-left: 15px;
}
.links {
  display: flex;
  flex-direction: row;
}
.skillItem li {
  padding: 3px;
  margin: 5px;
}
@media only screen and (max-width: 600px) {
  .img-and-description {
    display: flex;
    flex-direction: column;
  }
  .desc-container img {
    width: 100%;
    height: 80%;
  }
  .project-links img {
    height: 20px;
    width: 20px;
    margin: 0 10px;
  }
}
